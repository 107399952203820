<template>
  <div>
    <h2>Expositionsklasse</h2>
    <p>Die Expositionsklasse richtet sich nach den Umgebungsbedingungen der VMM-Decke: XC1 bei trockener Umgebung (Regelfall bei Innenräumen) und XC2/XC3 in feuchter Umgebung. Maßgebend für eine Platte ohne Kragarm ist die Umgebung unterhalb der Decke.</p>
  </div>
</template>

<script>
export default {
  name: "FieldOfApplicationHelp"
};
</script>

<style lang="scss">
</style>